/**
 * icon:菜单项图标
 * roles:标明当前菜单项在何种角色下可以显示，如果不写此选项，表示该菜单项完全公开，在任何角色下都显示
 */
const menuList = [
  {
    title: '首页',
    path: '/dashboard',
    icon: 'home',
    roles: ['admin', 'editor', 'guest'],
  },
  {
    title: '用户管理',
    path: '/user',
    icon: 'usergroup-add',
    roles: ['admin'],
  },
  {
    title: '会议室管理',
    path: '/room',
    icon: 'reconciliation',
    roles: ['admin', 'editor'],
  },
  {
    title: '订单管理',
    path: '/order',
    icon: 'ordered-list',
    roles: ['admin', 'editor'],
  },
  {
    title: 'banner配置',
    path: '/banner',
    icon: 'appstore',
    roles: ['admin', 'editor'],
  },
  {
    title: '反馈记录',
    path: '/feedback',
    icon: 'message',
    roles: ['admin', 'editor'],
  },
  {
    title: '素材库',
    path: 'picture',
    icon: 'picture',
    roles: ['admin', 'editor'],
  },
];
export default menuList;
