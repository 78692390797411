import * as types from '../action-types';
import { reqUserInfo } from '@/api/user';

export const setUserToken = token => ({
  type: types.USER_SET_USER_TOKEN,
  token,
});

export const setUserInfo = userInfo => ({
  type: types.USER_SET_USER_INFO,
  ...userInfo,
});

export const resetUser = () => ({
  type: types.USER_RESET_USER,
});

export const getUserInfo = () => dispatch =>
  new Promise((resolve, reject) => {
    reqUserInfo()
      .then(response => {
        if (response.status === 200) {
          const userInfo = response.data;
          dispatch(
            setUserInfo({
              ...userInfo,
              role: 'admin',
            })
          );
          resolve(response);
        } else {
          const msg = response.message;
          reject(msg);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
