import { setUserToken } from './user';
import { reqLogin } from '@/api/login';
import { setToken, removeToken } from '@/utils/auth';
export const login = (username, password) => dispatch =>
  new Promise((resolve, reject) => {
    reqLogin({ username: username.trim(), password })
      .then(res => {
        console.log('response', res);
        if (res.status === 200) {
          const { data } = res;
          const { token } = data;
          dispatch(setUserToken(token));
          setToken(token);
          resolve(data);
        } else {
          const msg = res.message;
          reject(msg);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const logout = token => dispatch =>
  new Promise((resolve, reject) => {
    removeToken();
    resolve(true);
  });
