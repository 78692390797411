import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserInfo } from '@/store/actions';
import Layout from '@/views/layout';
import Login from '@/views/login';
// eslint-disable-next-line react/prefer-stateless-function
class Router extends React.Component {
  render() {
    const { token, role, getUserInfo } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            path="/"
            render={() => {
              if (!token) {
                return <Redirect to="/login" />;
              } else {
                if (role) {
                  return <Layout />;
                } else {
                  getUserInfo().then(() => <Layout />);
                }
              }
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(state => state.user, { getUserInfo })(Router);
