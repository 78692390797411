import Loadable from 'react-loadable';
import Loading from '@/components/Loading';
const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName:'Dashboard'*/ '@/views/dashboard'),
  loading: Loading,
});
const KeyboardChart = Loadable({
  loader: () => import(/* webpackChunkName:'KeyboardChart'*/ '@/views/charts/keyboard'),
  loading: Loading,
});
const LineChart = Loadable({
  loader: () => import(/* webpackChunkName:'LineChart'*/ '@/views/charts/line'),
  loading: Loading,
});
const MixChart = Loadable({
  loader: () => import(/* webpackChunkName:'MixChart'*/ '@/views/charts/mixChart'),
  loading: Loading,
});
const User = Loadable({ loader: () => import(/* webpackChunkName:'User'*/ '@/views/user'), loading: Loading });
const Room = Loadable({ loader: () => import(/* webpackChunkName:'Room'*/ '@/views/room'), loading: Loading });
const Order = Loadable({ loader: () => import(/* webpackChunkName:'Order'*/ '@/views/order'), loading: Loading });
const Feedback = Loadable({
  loader: () => import(/* webpackChunkName:'Feedback'*/ '@/views/feedback'),
  loading: Loading,
});
const Picture = Loadable({ loader: () => import(/* webpackChunkName:'Pic'*/ '@/views/picture'), loading: Loading });
const Banner = Loadable({ loader: () => import(/* webpackChunkName:'Banner'*/ '@/views/banner'), loading: Loading });
const Error404 = Loadable({
  loader: () => import(/* webpackChunkName:'Error404'*/ '@/views/error/404'),
  loading: Loading,
});

export default [
  { path: '/dashboard', component: Dashboard, roles: ['admin', 'editor', 'guest'] },
  { path: '/charts/keyboard', component: KeyboardChart, roles: ['admin', 'editor'] },
  { path: '/charts/line', component: LineChart, roles: ['admin', 'editor'] },
  { path: '/charts/mix-chart', component: MixChart, roles: ['admin', 'editor'] },
  { path: '/user', component: User, roles: ['admin'] },
  { path: '/room', component: Room, roles: ['admin'] },
  { path: '/order', component: Order, roles: ['admin'] },
  { path: '/banner', component: Banner, roles: ['admin'] },
  { path: '/feedback', component: Feedback, roles: ['admin'] },
  { path: '/picture', component: Picture, roles: ['admin'] },
  { path: '/error/404', component: Error404 },
];
