import request from '@/utils/request';

export function reqUserInfo(data) {
  return request({
    url: '/user/info',
    method: 'get',
    data,
  });
}

export function get(params) {
  return request({
    url: '/user/list',
    method: 'get',
    params,
  });
}

export function del(data) {
  return request({
    url: '/user/del',
    method: 'post',
    data,
  });
}

export function edit(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data,
  });
}

export function add(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data,
  });
}
